import { Endpoints } from './constants';
import type { TenantLookupResponse } from './types';
import { fetcher } from './utils';

const lookupTenant = async (userEmail: string, workflowId?: string): Promise<TenantLookupResponse> => {
  return fetcher(
    Endpoints.TENANT_LOOKUP_BY_DOMAIN,
    {
      method: 'POST',
      body: JSON.stringify({ userEmail }),
      workflowId,
    },
    false,
  );
};

const getClientUserSession = async (workflowId: string) => {
  return fetcher(
    Endpoints.GET_CLIENT_USER_SESSION,
    {
      method: 'POST',
      workflowId,
    },
    false,
  );
};

const getLandingPage = async (workflowId: string, tenantId: string) => {
  return fetcher(
    Endpoints.GET_LANDING_PAGE,
    {
      method: 'POST',
      workflowId,
      headers: {
        'Impr-Tenant-Id': tenantId,
      },
    },
    false,
  );
};

const userLogout = async (tenantId: string) => {
  return fetcher(
    Endpoints.LOGOUT,
    {
      method: 'GET',
      headers: {
        'Impr-Tenant-Id': tenantId,
      },
    },
    false,
  );
};

export { lookupTenant, getClientUserSession, getLandingPage, userLogout };
