import { CommonHeaders, type Endpoints } from './constants';

export interface RequestInitImpr extends RequestInit {
  workflowId?: string | null;
}

/// Shared fetch wrapper
export const fetcher = async (
  endpoint: (typeof Endpoints)[keyof typeof Endpoints] | string,
  { workflowId, ...options }: RequestInitImpr,
  includeTenantId = true,
) => {
  const tenantIdHeader = {
    'Impr-Tenant-Id': new URLSearchParams(window.location.search).get('tenantId') || '',
  };

  const apiUrl = getApiUrl();

  return fetch(`${apiUrl}${endpoint}`, {
    ...options,
    credentials: 'include',
    headers: {
      // todo: we need client name
      ...CommonHeaders,
      ...(options.headers || {}),
      ...(workflowId ? { 'Impr-Workflow-Id': workflowId } : {}),
      ...(includeTenantId ? tenantIdHeader : {}),
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response
          .json()
          .then((json) => json)
          .catch(() => null); //response with empty body
      }
      const error = await response.json().then((json) => json);
      return Promise.reject(error.error);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      return Promise.reject(error);
    });
};

// Interpolate the API URL based on the current environment
export const getApiUrl = (tenantId?: string): string => {
  const { currentEnv, tld } = _getEnv();
  const host = tenantId ? tenantId : 'astra';
  return currentEnv === 'prod'
    ? 'https://astra.sys.imprivata.com'
    : `https://${host}.sys.${currentEnv}.imprivata.${tld}`;
};

// Get the current environment and top-level domain from the current host
export function _getEnv(): { currentEnv: string; tld: string } {
  const url = window.location.host;
  const urlRegex = /\w+\.(\w+)\.\w+\.(\w+)/gim;
  const matches = urlRegex.exec(url);

  let currentEnv = 'prod';
  let tld = 'com';

  if (url?.includes('localhost')) {
    currentEnv = 'local';
  } else if (Array.isArray(matches) && matches.length > 0) {
    matches.shift();
    const [appEnv, _tld] = matches;
    console.debug(appEnv, _tld);

    currentEnv = appEnv;
    tld = _tld;
  }

  return { currentEnv, tld };
}
