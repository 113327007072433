import { Route, Routes } from 'react-router-dom';
import PortalHome from './containers/PortalHome';
import { PortalLanding } from './containers/landing/PortalLanding';
import PortalLayout from './containers/layout/PortalLayout';

function PortalRoutes() {
  return (
    <Routes>
      <Route
        index
        element={
          <PortalLayout title={''}>
            <PortalHome />
          </PortalLayout>
        }
      />
      <Route path={'portal-landing'} element={<PortalLanding />} />
    </Routes>
  );
}

export default PortalRoutes;
