import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getClientUserSession, userLogout } from '../../api/portalServices';
import type { GetClientUserSessionResponse } from '../../api/types';
import { useNotifications } from '../../errorHandler/context/Notifications';
import { AppError } from '../../errorHandler/errors';
import { SPANS_PORTAL, initTracerPortal, tracer } from '../../utils/tracer';
import { PortalLanding } from './landing/PortalLanding';
import { PortalLogin } from './login/PortalLogin';

const PortalHome = () => {
  const { emitError } = useNotifications();
  const [userSessionData, setUserSessionData] = useState<GetClientUserSessionResponse>({});
  const [workflowId, setWorkflowId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const onLogout = async (tenantId: string) => {
    console.debug('Logout');
    await userLogout(tenantId)
      .then(() => {
        console.log('Logged out');
        setUserSessionData({});
      })
      .catch((e) => {
        console.error('Error logging out:', e);
        emitError(new AppError(e));
      });
  };

  useEffect(() => {
    const wid = uuidv4();
    const checkUserSession = async () => {
      // call getClientUserSession to check if the user is logged in
      return await getClientUserSession(wid || '');
    };
    setWorkflowId(wid);
    initTracerPortal(wid);
    tracer.startSpan(SPANS_PORTAL.check_session);

    checkUserSession()
      .then((response) => {
        setIsLoading(false);
        if (response) {
          setUserSessionData(response);
        }
        tracer.endSpan(SPANS_PORTAL.check_session);
      })
      .catch((e) => {
        setIsLoading(false);
        // Log error and go to the login page.
        tracer.endSpan(SPANS_PORTAL.check_session, { error: e });
      });
  }, []);

  console.debug('[PortalHome] workflowId:', workflowId);

  if (isLoading) {
    return <></>;
  }

  const pageToRender = userSessionData?.tenantId ? (
    <PortalLanding workflowId={workflowId} tenantId={userSessionData?.tenantId} onLogout={onLogout} />
  ) : (
    <PortalLogin workflowId={workflowId} />
  );
  return <>{pageToRender}</>;
};

export default PortalHome;
