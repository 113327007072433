import Icon from '@ant-design/icons';
import { Button } from '@imprivata-cloud/components';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as api from '../../../api/setupServices';
import copyIcon from '../../../assets/icons/copy.svg?react';
import ContinueButton from '../../../components/continueButton/ContinueButton';
import './TrustImprivataServers.less';
import { STEPS, TRANSLATION_BUTTON } from '../../constants';

const TRANSLATION_ROOT = `setup.${STEPS.TRUST_IMPRIVATA_SERVERS}.content`;

const TrustImprivataServers = () => {
  const { t } = useTranslation();
  const [ipRanges, setIpRanges] = useState<string[]>([]);

  // Fetch IP ranges on component load
  useEffect(() => {
    // TODO: Error?
    api.getIpRanges().then((ranges) => {
      setIpRanges(ranges);
    });
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard:', text);
      })
      .catch((err) => {
        console.log('Failed to copy the text. Please try again.', err);
      });
  };

  const handleCopyIpRanges = () => {
    const formattedIpRanges = ipRanges.join('\n');
    copyToClipboard(formattedIpRanges);
  };

  const handleNextStep = () => {
    console.log('Go to Connect EntraID');
  };

  return (
    <>
      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.description`} /> &nbsp;
        <a href="https://example.com" target="_blank" rel="noopener noreferrer">
          {t(`${TRANSLATION_ROOT}.see-instructions`)}
        </a>
      </p>
      <p>
        <Trans i18nKey={`${TRANSLATION_ROOT}.security-name-locations`} />
      </p>

      <p>
        <strong>1</strong>&nbsp;
        {t(`${TRANSLATION_ROOT}.1-ip-ranges.title`)}
      </p>
      <ul>
        {[1, 2, 3].map((step) => (
          <li key={`ip-ranges-step-${step}`} className="body">
            <Trans i18nKey={`${TRANSLATION_ROOT}.1-ip-ranges.step-${step}`} />
          </li>
        ))}
      </ul>

      <div className={'ip-ranges'}>
        {ipRanges.map((ip) => (
          <div key={ip} className="ip-ranges-option">
            {ip}
          </div>
        ))}
      </div>

      <Button
        type="text"
        size="small"
        label={t('common.copy-all')}
        icon={<Icon component={copyIcon} />}
        onClick={handleCopyIpRanges}
        block
      />

      <p>
        <strong>2</strong>&nbsp;
        <Trans i18nKey={`${TRANSLATION_ROOT}.2-trusted-ips`} />
      </p>
      <ContinueButton label={t(TRANSLATION_BUTTON)} onClick={handleNextStep} />
    </>
  );
};

export default TrustImprivataServers;
