import { Tracer } from '@imprivata-cloud/common';
import { TracingSolutionType } from '@imprivata-cloud/common';
import { getApiUrl } from '../api/utils';
import { StorageKeys } from '../api/constants';

const apiUrl = getApiUrl();

export let tracer: Tracer;

export const SPANS_PORTAL = {
  check_session: 'check_user_session',
  lookup_tenant: 'lookup_tenant',
  get_portal_info: 'get_portal_info',
}

export const SPANS_SETUP = {
  start_setup: 'tenant_setup_start',
  dpa_check: 'dpa_check',
  icp_metadata_save: 'icp_metadata_save',
  get_upload_url: 'get_upload_url',
  upload_file: 'upload_file',
  save_org_info: 'save_org_info',
  get_aad_consent_url: 'get_aad_consent_url',
  complete_setup: 'complete_setup',
}

//  if page is refreshed in the middle of the setup, you keep the workflowId
if (getWorkflowId() && window.location.pathname?.startsWith('/setup')) {
  initTracerSetup(getWorkflowId());
}

export function getWorkflowId(): string {
  return sessionStorage.getItem(StorageKeys.WORKFLOW_ID) || '';
}

export function initTracerSetup(workflowId: string) {
  initTracer('setup-ui', workflowId);
}

export function initTracerPortal(workflowId: string) {
  initTracer('portal-ui', workflowId);
}

export function initTracer(serviceName: string, workflowId: string) {
  tracer = new Tracer(
    serviceName,
    workflowId,
    {
      logToConsole: true,
      collectSpans: true, // used for local development and CI
      otlpExportUrl: `${apiUrl}/v1/traces`,
    },
    undefined,
    TracingSolutionType.DYNATRACE,
  );
}

