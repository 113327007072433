// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { sendTenantSetupCompleteRequest } from '../../api/setupServices';
import { ErrorCode } from '../../errorHandler/constants';
import type { ApiError } from '../../errorHandler/errors';
import { FatalError } from '../../errorHandler/errors';
import { SPANS_SETUP, tracer } from '../../utils/tracer';

const useCompleteSetupMutation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return useMutation({
    mutationFn: (withEntraId: boolean) => sendTenantSetupCompleteRequest(withEntraId),
    onSuccess: () => {
      tracer.endSpan(SPANS_SETUP.complete_setup);
      console.log('Setup completed successfully');
      navigate({ pathname: '/setup/setup-complete', search: `?${searchParams.toString()}` });
    },
    onError: (error: ApiError) => {
      console.error('Error completing the setup:', error);
      tracer.endSpan(SPANS_SETUP.complete_setup, { error });
      throw new FatalError({ code: ErrorCode.FATAL_ERROR });
    },
  });
};

export default useCompleteSetupMutation;
